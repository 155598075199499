(function() {
    document.addEventListener("DOMContentLoaded", function() {
        // Bind the event listener to the navigation toggle button.
        const navToggle = document.querySelector('button.navbar-toggler');

        if (navToggle) {
            navToggle.addEventListener('click', function(e) {
                e.preventDefault();

                const body = document.body;
                const mainNavContainer = document.getElementById('darrochMainNav');

                if (!mainNavContainer) {
                    return;
                }

                if (mainNavContainer.classList.contains('active')) {
                    mainNavContainer.classList.remove('active');
                    body.classList.remove('mobile-nav-active');
                }
                else {
                    mainNavContainer.classList.add('active');
                    body.classList.add('mobile-nav-active');
                }
            });
        }
    });
})();